export const prettyStatus = (status) => {
    switch (status) {
        case 'AM': return 'Nowe';
        case 'DG': return 'Nowe';
        case 'HURT': return 'Przetwarzanie';
        case 'SENT': return 'Wysłane';
        case 'VOID': return 'Anulowane';
        case 'DONE': return 'Dostarczone';
        default: return 'Status nieznany';
    }
};

export const prettySubStatus = (status) => {
    switch (status) {
        case 'RE': return 'Przyjęte';
        case 'WS': return 'Wstrzymane';
        case 'PŁ': return 'Płatności';
        case 'FA': return 'Faktura';
        case 'LI': return 'Limity';
        case 'DO': return 'Dostawa';
        case 'DK': return 'Dokumenty';
        case 'PP': return 'Przedpłata';
        case 'ZW': return 'Zwrot';
        case 'ZC': return 'Zwrot częściowy';
        case 'KO': return 'Korekta';
        case 'KS': return 'Kongres';
        default: return null;
    }
};

export const prettyPaymentType = (paymentType) => {
    switch (paymentType) {
        case 'MT': return 'Przelew';
        case 'COD': return 'Polecenie zapłaty';
        case 'PP': return 'Przedpłata';
        default: return 'Inny';
    }
}

export const prettyPaymentStatus = (paymentstatus, failuresOnly = false) => {
    if (failuresOnly) {
        switch (paymentstatus) {
            case 'CANC':
                return 'Płatność anulowana';
            case 'PEND':
                return 'Płatność w realizacji';
            default:
                return null;
        }
    }

    switch (paymentstatus) {
        case 'DONE':
            return 'Płatność zrealizowana';
        case 'CANC':
            return 'Płatność anulowana';
        case 'PEND':
            return 'Płatność w realizacji';
        case 'NEW':
            return null;
        case null:
            return null;
        default:
            return 'Nieznany status płatności';
    }
}

export const prettyTerm = (days) => {
    return days === 1 ? `${days} dzień` : `${days} dni`;
}

export const prettyCurrency = (amount) => {
    return parseFloat(amount || 0).toLocaleString('pl-PL', { style: 'currency', currency: 'PLN' })
};

export const prettyPercent = (amount, maximumFractionDigits = 0) => {
    return parseFloat(amount / 100.0 || 0).toLocaleString('pl-PL', { style: 'percent', 'maximumFractionDigits': maximumFractionDigits })
};

export const prettyBoolean = (value) => {
    return value ? 'Tak' : 'Nie';
};

export const prettyString = (value, length) => {
    if (value.length <= length)
        return value;
    const breakAt = value.indexOf(' ', length);
    if (breakAt < 0)
        return value;
    return value.substring(0, breakAt) + '…';
};

export const prettyPhone = (value) => {
    return value.trim();
};

export const prettyDate = (value, nullValue = '') => {
    return value || nullValue;
};

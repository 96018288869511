import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Nav } from 'react-bootstrap';
import { CartContext } from '../../context/CartContext';
import { ReactComponent as CartIcon } from '../../assets/cart.svg';
import './CartNav.scss';

function CartNav({ navbarRef }) {
    const cart = useContext(CartContext);

    return (
        <Nav className="cart">
            <Nav.Link
                as={Link}
                to="/cart"
                className="icon-link"
                eventKey="cart"
            ><CartIcon />{cart?.totalItems > 0 && <span className="fa-layers-counter">{cart?.totalItems}</span>} Koszyk</Nav.Link>
        </Nav>
    );
}

export default CartNav;

import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { sendUiEvent } from '../utils/uievents';
import * as UIEventTypes from "../utils/uieventtypes";

function PrivateRoute({ component: Component, path, ...props }) {
    const auth = useContext(AuthContext);

    return (
        <Route
            path={path}
            {...props}
            render={({ location, ...iprops }) => {
                if (auth.isAuthenticated) {
                    sendUiEvent({ type: UIEventTypes.UIEVENT_UIACT, data: location?.pathname, institution_id: auth.institutionId });
                    return (<Component {...iprops} />);
                }
                else {
                    return (
                        <Redirect to={{ pathname: "/login", state: { from: location } }} />
                    );
                }
            }}
        />
    );
}

export default PrivateRoute;

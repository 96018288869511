import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { Container, Navbar } from 'react-bootstrap';
import CategoriesNav from '../pages/Catalog/CategoriesNav';
import CartNav from '../pages/Cart/CartNav';
import ProfileNav from '../pages/Profile/ProfileNav';
import { ReactComponent as Logo } from '../assets/galderma.svg';
import { ReactComponent as EllipsisVIcon } from '../assets/ellipsisv.svg';
import "./Header.scss";

function Header() {
    const navbarEl = useRef(null);

    return (
        <Container fluid>
            <Navbar collapseOnSelect expand="lg" ref={navbarEl}>
                <Navbar.Brand as={Link} to="/"><Logo /></Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav"><EllipsisVIcon /></Navbar.Toggle>
                <Navbar.Collapse id="basic-navbar-nav">
                    <CategoriesNav />
                    <ProfileNav />
                    <CartNav navbarRef={navbarEl} />
                </Navbar.Collapse>
            </Navbar>
        </Container>
    );
}

export default Header;

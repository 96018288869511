import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Loading from './components/Loading';
import PageContainer from './components/PageContainer';
import Header from './components/Header';
import Footer from './components/Footer';
import RouterConfig from './routing/RouterConfig';
import { AuthProvider } from './context/AuthContext';
import { dom } from '@fortawesome/fontawesome-svg-core';
import { registerLocale, setDefaultLocale } from "react-datepicker";
import pl from 'date-fns/locale/pl';
import * as Sentry from "@sentry/react";
import './App.scss';
import "react-datepicker/dist/react-datepicker.css";
import ErrorBoundary from './ErrorBoundary';
import Config from './Config';
import ScrollToTop from './controls/ScrollToTop';
import { CartProvider } from './context/CartContext';

dom.watch();

registerLocale('pl', pl);
setDefaultLocale('pl');

function LoadingContainer() {
    return (
        <PageContainer className="page-loader"
            main={<Loading />}
        />
    );
}

function App() {
    return (
        <ErrorBoundary>
            <Sentry.ErrorBoundary fallback={"Wystąpił nieoczekiwany błąd"} showDialog>
                <BrowserRouter basename={Config.publicUrl}>
                    <ScrollToTop />
                    <React.Suspense fallback={<LoadingContainer />}>
                        <AuthProvider
                            loadingView={
                                <LoadingContainer />
                            }
                            unauthorized={
                                <PageContainer
                                    className='unauthorized'
                                    main={<RouterConfig authenticated={false} />}
                                />
                            }
                            authorized={
                                <CartProvider>
                                    <PageContainer
                                        className="authorized"
                                        header={<Header />}
                                        main={<RouterConfig authenticated={true} />}
                                        footer={<Footer />}
                                    />
                                </CartProvider>
                            }
                        />
                    </React.Suspense>
                </BrowserRouter>
            </Sentry.ErrorBoundary>
        </ErrorBoundary>
    );
}

export default Sentry.withProfiler(App);

import React, { useContext } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import { AuthContext } from '../context/AuthContext';

const Login = React.lazy(() => import('../pages/Public/Login'));
const Register = React.lazy(() => import('../pages/Public/Register'));
const ResetPassword = React.lazy(() => import('../pages/Public/ResetPassword'));
const ChangePassword = React.lazy(() => import('../pages/Public/ChangePassword'));
const PublicArticle = React.lazy(() => import('../pages/Public/PublicArticle'));
const PublicCookiePolicy = React.lazy(() => import('../pages/Public/CookiePolicy'));
const CookiePolicy = React.lazy(() => import('../pages/CookiePolicy'));
const PublicError404 = React.lazy(() => import('../pages/Public/Error404'));
const Catalog = React.lazy(() => import('../pages/Catalog'));
const Home = React.lazy(() => import('../pages/Home'));
const PromoActions = React.lazy(() => import('../pages/PromoActions'));
const PromoAction = React.lazy(() => import('../pages/PromoActions/PromoAction'));
const Product = React.lazy(() => import('../pages/Catalog/Product'));
const Delivery = React.lazy(() => import('../pages/Cart/Delivery'));
const Summary = React.lazy(() => import('../pages/Cart/Summary'));
// const Payment = React.lazy(() => import('../pages/Cart/Payment'));
const Cart = React.lazy(() => import('../pages/Cart'));
const Password = React.lazy(() => import('../pages/Profile/Password'));
const Agreements = React.lazy(() => import('../pages/Profile/Agreements'));
const Profile = React.lazy(() => import('../pages/Profile'));
const ToBs = React.lazy(() => import('../pages/ToBs'));
const ToB = React.lazy(() => import('../pages/ToBs/ToB'));
const Orders = React.lazy(() => import('../pages/Orders'));
const Order = React.lazy(() => import('../pages/Orders/Order'));
const Payment = React.lazy(() => import('../pages/Payment'));
const Balance = React.lazy(() => import('../pages/Balance'));
const Contact = React.lazy(() => import('../pages/Contact'));
const Article = React.lazy(() => import('../pages/Article'));
const Documents = React.lazy(() => import('../pages/Documents'));
// const Portfolio = React.lazy(() => import('../pages/Portfolio'));
const Error404 = React.lazy(() => import('../pages/Error404'));

function RouterConfig() {
    const authContext = useContext(AuthContext);
    if (authContext.isAuthenticated === undefined)
        return null;

    return (
        <Switch>
            <PrivateRoute exact path="/" component={Home} />
            <PrivateRoute path="/catalog/:id" component={Catalog} />
            <PrivateRoute path="/catalog" component={Catalog} />
            <PrivateRoute path="/product/:id" component={Product} />
            <PrivateRoute path="/cart/delivery" component={Delivery} />
            <PrivateRoute path="/cart/summary" component={Summary} />
            {/* <PrivateRoute path="/cart/payment" component={Payment} /> */}
            <PrivateRoute path="/cart" component={Cart} />
            <PrivateRoute path="/promo/:id" component={PromoAction} />
            <PrivateRoute path="/promo" component={PromoActions} />
            <PrivateRoute path="/orders/:id" component={Order} />
            <PrivateRoute path="/orders" component={Orders} />
            <PrivateRoute path="/history/:id" component={Order} />
            <PrivateRoute path="/history" component={Orders} />
            <PrivateRoute path="/payment" component={Payment} />
            <PrivateRoute path="/balance" component={Balance} />
            <PrivateRoute path="/tobs/:id" component={ToB} />
            <PrivateRoute path="/tobs" component={ToBs} />
            <PrivateRoute path="/password" component={Password} />
            <PrivateRoute path="/profile" component={Profile} />
            <PrivateRoute path="/agreements" component={Agreements} />
            <PrivateRoute path="/contact" component={Contact} />
            {/* <PrivateRoute path="/portfolio" component={Portfolio} /> */}
            <Route path="/public/article/:id"><PublicArticle /></Route>
            <PrivateRoute path="/article/:id" component={Article} />
            <PrivateRoute path="/documents" component={Documents} />
            <Route path="/cookie-policy">{authContext.isAuthenticated ? <CookiePolicy /> : <PublicCookiePolicy />}</Route>
            <Route path="/changepassword">{authContext.isAuthenticated ? <Redirect to="/" /> : <ChangePassword />}</Route>
            <Route path="/login">{authContext.isAuthenticated ? <Redirect to="/" /> : <Login />}</Route>
            <Route path="/register">{authContext.isAuthenticated ? <Redirect to="/" /> : <Register />}</Route>
            <Route path="/resetpassword">{authContext.isAuthenticated ? <Redirect to="/" /> : <ResetPassword />}</Route>
            <Route path="*">{authContext.isAuthenticated ? <Error404 /> : <PublicError404 />}</Route>
        </Switch>
    );
}

export default RouterConfig;


import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Row, Col, Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import useFetch from 'use-http';
import useSessionstorage from "@rooks/use-sessionstorage";
import { AuthContext } from '../context/AuthContext';
import * as Sentry from "@sentry/react";
import './PaymentReminder.scss';
import Config from '../Config';

function Plural({ value, zeroMessage = null, oneMessage = null, twoMessage = null, fiveMessage = null }) {
    if (value === 0) {
        return zeroMessage || fiveMessage;
    }
    if (value === 1) {
        return oneMessage || fiveMessage;
    }
    if (value >= 2 && value < 5) {
        return twoMessage || fiveMessage;
    }
    return fiveMessage;
}

function PaymentReminder() {
    const auth = useContext(AuthContext);
    const [showOverdue, setShowOverdue] = useSessionstorage('showOverdue', []);
    const [showUnpaid, setShowUnpaid] = useSessionstorage('showUnpaid', []);
    const [invoices, setInvoices] = useState();
    const { get, response, error } = useFetch(Config.apiUrl, { credentials: 'include' });

    const loadInvoices = useCallback(async () => {
        const result = await get(`/caapi/v1/invoices?type=remind&institution_id=${auth.institutionId}`);
        if (response.ok) {
            setInvoices(result);
        }
        else {
            Sentry.captureMessage('GETINVOICES ' + (result?.error_description || error?.message));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [get, response, auth]);

    useEffect(() => {
        loadInvoices();
    }, [loadInvoices]);

    const hideOverdue = function () {
        var newOverdue = [...showOverdue, auth.institutionId];
        sessionStorage.setItem('showOverdue', newOverdue);
        setShowOverdue(newOverdue);
    };

    const hideUnpaid = function () {
        var newUnpaid = [...showUnpaid, auth.institutionId];
        sessionStorage.setItem('showUnpaid', newUnpaid);
        setShowUnpaid(newUnpaid);
    };

    if (invoices && invoices.length > 0 && (showOverdue || showUnpaid)) {
        const unpaid = invoices.filter(invoice => invoice.overduedays === 0);
        const overdue = invoices.filter(invoice => invoice.overduedays > 0);

        if (showOverdue === true || showOverdue === false) {
            sessionStorage.removeItem('showOverdue');
        }
        if (showUnpaid === true || showUnpaid === false) {
            sessionStorage.removeItem('showUnpaid');
        }

        var so = auth?.institutionId ? showOverdue?.includes(auth?.institutionId) : true;
        var su = auth?.institutionId ? showUnpaid?.includes(auth?.institutionId) : true;
        return (
            <Row className="full-width">
                <Col className="payment-reminder full-width">
                    {(!so && overdue.length > 0) && <Alert variant="danger" onClose={hideOverdue} dismissible>
                        <Plural
                            value={overdue.length}
                            zeroMessage={<>Wszystkie faktury są opłacone. <Link to="/balance" className="">Przejdź do faktur.</Link></>}
                            oneMessage={<>Masz {overdue.length} nieopłaconą fakturę. <Link to="/balance" className="">Przejdź do faktur.</Link></>}
                            twoMessage={<>Masz {overdue.length} nieopłacone faktury. <Link to="/balance" className="">Przejdź do faktur.</Link></>}
                            fiveMessage={<>Masz {overdue.length} nieopłaconych faktur. <Link to="/balance" className="">Przejdź do faktur.</Link></>}
                        />
                    </Alert>}
                    {(!su && unpaid.length > 0) && <Alert variant="warning" onClose={hideUnpaid} dismissible>
                        <Plural
                            value={unpaid.length}
                            zeroMessage={<>Nie masz żadnych nadchodzących płatności. <Link to="/balance" className="">Przejdź do faktur.</Link></>}
                            oneMessage={<>Masz {unpaid.length} nadchodzącą płatność. <Link to="/balance" className="">Przejdź do faktur.</Link></>}
                            twoMessage={<>Masz {unpaid.length} nadchodzące płatności. <Link to="/balance" className="">Przejdź do faktur.</Link></>}
                            fiveMessage={<>Masz {unpaid.length} nadchodzących płatności. <Link to="/balance" className="">Przejdź do faktur.</Link></>}
                        />
                    </Alert>}
                </Col>
            </Row>
        );
    }
    return null;
}

export function cleanup() {
    sessionStorage.removeItem('showOverdue');
    sessionStorage.removeItem('showUnpaid');
}

export default PaymentReminder;

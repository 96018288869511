import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import App from './App';
import * as serviceWorker from './serviceWorker';
import './index.scss';
import Config from './Config';
import TagManager from 'react-gtm-module'

if (Config.sentryDSN) {
    Sentry.init({
        dsn: Config.sentryDSN,
        integrations: [new Integrations.BrowserTracing()],
        // release: Config.sentryRelease + "@" + process.env.npm_package_version,
        release: Config.version,
        environment: Config.sentryEnvironment,
        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 1.0
    });
}

if (Config.googleGTM) {
    TagManager.initialize({
        gtmId: Config.googleGTM,
        // preview: Config.sentryEnvironment
    });
}

/** 
 * TODO wyłączone, bo react-bootstrap nie obsługuje trybu strict
 * https://github.com/react-bootstrap/react-bootstrap/issues/5519
 * https://github.com/react-bootstrap/react-bootstrap/issues/5075
*/
// ReactDOM.render(
//     <React.StrictMode>
//         <App />
//     </React.StrictMode>,
//     document.getElementById('root')
// );
ReactDOM.render(
    <App />,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Nav, NavDropdown } from 'react-bootstrap';
import { ReactComponent as PersonIcon } from '../../assets/person.svg';
import { AuthContext } from '../../context/AuthContext';
import { prettyString } from '../../utils/pretty';
import "./ProfileNav.scss";

function ProfileNav() {
    const auth = useContext(AuthContext);

    function logout(e) {
        e.preventDefault();
        auth.logout();
    }

    function changeInstitution(e, id) {
        e.preventDefault();
        auth.changeInstitution(id);
    }

    function Title() {
        return (<><PersonIcon /> Konto</>);
    }

    return (
        <Nav className="profile">
            <NavDropdown title={<Title />} id="collasible-nav-dropdown">
                {auth?.institutions.length === 0 ?
                    <NavDropdown.Item as="div" className="dropdown-header">Brak powiązanych działalności</NavDropdown.Item> :
                    <>
                        <NavDropdown.Item as="div" className="dropdown-header">Wybrana działalność</NavDropdown.Item>
                        {auth?.institutions?.filter(institution => {
                            return institution.sell;
                        }).map(institution => (
                            <NavDropdown.Item
                                key={institution.id}
                                eventKey={institution.id}
                                // as={Link}
                                href="/"
                                active={auth.institutionId === institution.id}
                                onClick={e => changeInstitution(e, institution.id)}
                            >
                                {prettyString(institution.longname, 25)}
                            </NavDropdown.Item>
                        ))}
                    </>
                }
                <NavDropdown.Divider />
                <NavDropdown.Item as="div" className="dropdown-header">{auth.userName}</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/profile" eventKey="profile">Mój profil</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/agreements" eventKey="agreements">Zgody</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/password" eventKey="password">Hasło</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item as="div" className="dropdown-header">Działalność</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/orders" eventKey="orders">Bieżące zamówienia</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/history" eventKey="history">Historia zamówień</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/balance" eventKey="balance">Faktury</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/tobs" eventKey="tobs">Wspólny Plan Biznesowy</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/promo" eventKey="promo">Oferty handlowe</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/documents" eventKey="documents">Druki</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="/" onClick={e => logout(e)}>Wyloguj</NavDropdown.Item>
            </NavDropdown>
        </Nav>
    );
}

export default ProfileNav;

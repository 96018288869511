import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Nav, NavDropdown } from 'react-bootstrap';
import useAPI from '../../hooks/useAPI';
import './CategoriesNav.scss';

function CategoriesNav() {
    const { data: productgroups } = useAPI('productlinegroups');
    const location = useLocation();

    return (
        <Nav className="categories" activeKey={location?.pathname}>
            {productgroups?.filter(productgroup => productgroup.parent_id === null).map(productgroup =>
            (productgroup.haschildren ?
                <NavDropdown title={productgroup.name} key={productgroup.id}>
                    {/* <NavDropdown.Item as="div" className="dropdown-header">Wybierz kategorię</NavDropdown.Item> */}
                    {productgroups?.filter(subgroup => subgroup.parent_id === productgroup.id).map(subgroup =>
                        <NavDropdown.Item
                            as={Link}
                            key={subgroup.id}
                            to={subgroup.productline_id ? `/product/${subgroup.productline_id}` : `/catalog/${subgroup.urlname || subgroup.id}`}
                            eventKey={subgroup.productline_id ? `/product/${subgroup.productline_id}` : `/catalog/${subgroup.urlname || subgroup.id}`}
                        >{subgroup.name}</NavDropdown.Item>
                    )}
                </NavDropdown>
                : <Nav.Link
                    as={Link}
                    key={productgroup.id}
                    to={productgroup.productline_id ? `/product/${productgroup.productline_id}` : `/catalog/${productgroup.urlname || productgroup.id}`}
                    eventKey={productgroup.productline_id ? `/product/${productgroup.productline_id}` : `/catalog/${productgroup.urlname || productgroup.id}`}
                ><div>{productgroup.name}</div></Nav.Link>)
            )}
        </Nav>
    );
}

export default CategoriesNav;

import Config from '../Config';

export const sendUiEvent = async (data) => {
    // if we don't need to wait for response and sendBeacon is available in browser
    if (data.wait !== true && navigator.sendBeacon) {
        if (navigator.sendBeacon(Config.apiUrl + '/caapi/v1/uievents', new URLSearchParams(data))) {
            return {};
        }
        // else {
        // not queued, send as POST
        // }
    }

    const response = await fetch(Config.apiUrl + '/caapi/v1/uievents', {
        keepalive: true,
        method: 'POST',
        cachePolicy: 'no-cache',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    });
    await response.json();
    if (!response.ok) {
        return null;
    }
    return response;
}

import React, { createContext, useContext, useEffect, useState } from "react";
import useSessionstorage from "@rooks/use-sessionstorage";
import useFetch from 'use-http';
import { AuthContext } from '../context/AuthContext';
import * as Sentry from "@sentry/react";
import Config from "../Config";

export const CartContext = createContext({
    promoActionId: null,
    setPromoActionId: () => { },
    cartItems: [],
    saleData: { deliveryaddresses: [], note: '', paymenttype: '' }
});

export function CartProvider({ children }) {
    const auth = useContext(AuthContext);
    const [promoActionId, setPromoActionId] = useSessionstorage("promoaction_id", null);
    const [dirty, setDirty] = useState(true);
    const [cartItems, setCartItems] = useState();
    const [saleData, setSaleData] = useSessionstorage('sale_data', {});
    const [errorMessage, setErrorMessage] = useState();
    const { get, del, post, patch, response, error, loading, cache } = useFetch(Config.apiUrl, {
        cachePolicy: 'no-cache',
        credentials: 'include',
        headers: {
            'Content-type': 'application/json; charset=UTF-8'
        }
    });
    useEffect(() => {
        const pid = cartItems?.reduce((prev, curr) => { return prev ? prev : curr.promoaction_id; }, null);
        setPromoActionId(pid ?? null);
    }, [cartItems, setPromoActionId]);

    useEffect(() => {
        if (auth.isAuthenticated && auth.institutionId && dirty) {
            const loadCartItems = async () => {
                setErrorMessage(null);
                const result = await get(`/caapi/v1/cartitems?institution_id=${auth.institutionId}?o=productline_name`);
                if (response.ok) {
                    setCartItems(result);
                    setDirty(false);
                }
                else {
                    Sentry.captureMessage('GETCART ' + (result?.error_description || error?.message));
                    setErrorMessage(result?.error_description || "Wystąpił nieznany błąd.");
                }
            }
            loadCartItems();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth.institutionId, auth.isAuthenticated, dirty, get, response]);

    const addCartItem = async (cartitem) => {
        setErrorMessage(null);
        // const data = new FormData();
        // for (const [key, value] of Object.entries(cartitem)) {
        //     data.append(key, value);
        // }
        const result = await post('/caapi/v1/cartitems', cartitem);
        if (response.ok) {
            // if (post_response.ok) {
            // setDirty(true); // reload cart
            setCartItems(result);
            return result;
        }
        else {
            Sentry.captureMessage('POSTCARTITEM ' + (result?.error_description || error?.message));
            setErrorMessage(result?.error_description || "Wystąpił nieznany błąd.");
            return null;
        }
    }

    const changeCartItem = (id, quantity) => {
        const doChange = async () => {
            if (quantity === '' || parseInt(quantity) < 1) {
                return null;
            }
            setErrorMessage(null);
            const result = await patch(`/caapi/v1/cartitems/${id}`, {
                quantity
            });
            if (response.ok) {
                // const modified = cartItems.map(ci => {
                //     if (ci.id === id) {
                //         const total = { ...ci, ...result };
                //         return total;
                //     }
                //     return ci;
                // });

                // setCartItems(modified);
                // setDirty(true); // no need to reload cart

                // force refresh, bo z jakiegoś powodu samo ustawienie na nowo cartItems
                // nie wymuszało odświeżenia layoutu i przy ustawieniu quantity powyżej limitu nie powodowało
                // odświeżenia widoku z wczytanej wartości
                setCartItems(null);
                setCartItems(result);
                return result;
            }
            else {
                Sentry.captureMessage('PATCHCARTITEM ' + (result?.error_description || error?.message));
                setErrorMessage(result?.error_description || "Wystąpił nieznany błąd.");
                return null;
            }

        }
        doChange();
    }

    const removeCartItem = async (id) => {
        setErrorMessage(null);
        setCartItems(cartItems.filter((cartItem => cartItem.id !== id)));

        const result = await del(`/caapi/v1/cartitems/${id}`);
        if (response.ok) {
            // setDirty(true);
            // cache.clear();
            setCartItems(null);
            setCartItems(result);
            return result;
        }
        else {
            Sentry.captureMessage('DELCARTITEM ' + (result?.error_description || error?.message));
            setErrorMessage(result?.error_description || "Wystąpił nieznany błąd.");
        }
    }

    const clearCart = () => {
        setErrorMessage(null);
        setCartItems([]);
        const doClearCart = async (e) => {
            const result = await del('/caapi/v1/cartitems');
            if (response.ok) {
                setDirty(true);
                setSaleData({ saleData, [auth.institutionId]: null, deliveryaddresses: [] }); // clear sale data
                cache.clear();
            }
            else {
                Sentry.captureMessage('DELCARTITEMS ' + (result?.error_description || error?.message));
                setErrorMessage(result?.error_description || "Wystąpił nieznany błąd.");
            }
        };
        doClearCart();
    }

    useEffect(() => {
        setDirty(true);
    }, [auth.institutionId]);

    // const totalQuantity = cartItems?.reduce((prev, current) => { return prev += parseInt(current.quantity); }, 0);
    const totalItems = cartItems?.length;

    return (
        <CartContext.Provider value={{
            promoActionId,
            setPromoActionId,
            saleData: saleData?.[auth.institutionId],
            setSaleData: (value) => {
                const data = saleData || {};
                setSaleData({ ...data, [auth.institutionId]: value });
            },
            attachmentRequired: cartItems?.filter(item => item.attachmentrequired).length > 0, // czy wymagany jest załącznik dla azzalure i alluzience
            profarmAgreementRequired: cartItems?.length !== cartItems?.filter(item => item.rxsale).length, // są też inne rzeczy niż medyczne
            pharmacyAgreementRequired: cartItems?.filter(item => item.rxsale).length > 0, // czy są produkty medyczne
            // onlyprepayment: cartItems?.filter(item => item.onlyprepayment).length > 0,
            paymentTypePrePayment: cartItems?.filter(item => item.paymenttype_prepayment).length === cartItems?.length,
            paymentTypeMoneyTransfer: cartItems?.filter(item => item.paymenttype_moneytransfer).length === cartItems?.length,
            paymentTypeCashOnDemand: cartItems?.filter(item => item.paymenttype_cashondemand).length === cartItems?.length,
            cartItems,
            setCartItems,
            totalItems,
            loading,
            error,
            errorMessage,
            response,
            cache,
            addCartItem,
            changeCartItem,
            removeCartItem,
            clearCart
        }}>
            {children}
        </CartContext.Provider>
    );
}

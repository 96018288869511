import React, { useEffect } from 'react';
import Config from '../Config';

function DownloadLink({ to, publicAccess = true, ...props }) {
    const children = props.children;

    return (
        <a href={`${publicAccess ? Config.publicFileUrl : Config.fileUrl}${to}?download=1`}>{children}</a>
    );
}

export default DownloadLink
